@import "../../theme/variables.scss";

.date-picker-container {
  position: relative;
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.date-picker-input {
  height: 40px;
  width: 100%;
  padding: 0 30px;
  border-radius: 4px;
  border: 1px solid $primary-color;
  outline: none;
  border-color: initial;
  color: $primary-color;
  font-family: "Open-Sans";
  font-size: 12px;
}
.calendar-icon-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.calendar-icon {
  width: 17px;
  height: 16px;
  cursor: pointer;
}
.calendar-date-icon {
  width: 18px;
  height: 20px;
}

.error-date-picker-container {
  height: 40px;
  width: 100%;
  padding: 0 30px;
  border-radius: 4px;
  border: 1px solid red !important;
  outline: none;
  border-color: initial;
  color: $primary-color;
  font-family: "Open-Sans";
  font-size: 12px;
}
